export default color =>
  ({
    skyBlue: '#5ac6f7',
    blue: '#4ba6e1',
    offBlack: '#212529',
    black: '#2e3033',
    offWhite: '#f8f9fa',
    white: '#fff',
    cyan: '#1864ab',
    lightCyan: '#d0ebff',
    lighterCyan: '#e7f5ff',
    offWhite: '#F7F9FA',
    openBlue: '#1864ab',
    paleGrey: '#8898aa',
    lightGrey: '#f1f3f5',
    lighterGrey: '#edf2f7',
    indigo: '#edf2ff',
    slate: '#3e5467',
    red: '#f5496c',
    orange: '#fa755a',
    teal: '#20c997',
    lightTeal: '#74E4A2',
    pink: '#fcc2d7',
    green: '#1BB978',
    ligthTeal: '#B9F4BC',
    ligtherTeal: '#B9F4BC',
    lightSky: '#f6f9fc',
    purple: '#8f6ed5',

    herokuBlue: '#4A90E2',
    herokuGray: '#E5E5E5',
    heroku: '#7952a5',

    mainBlue: '#5AC7F7'
  }[color])
