import { rem } from 'polished'

const base = '16px'

export default {
  base,
  lineHeight: {
    xxxl: rem('64px', base),
    xxl: rem('48px', base),
    xl: rem('36px', base),
    l: rem('30px', base),
    m: rem('24px', base),
    s: rem('22px', base),
    xs: rem('20px', base),
    xxs: rem('17px', base),
    xxxs: rem('15px', base)
  },
  size: {
    xxxl: rem('48px', base),
    xxl: rem('36px', base),
    xl: rem('28px', base),
    l: rem('22px', base),
    m: rem('18px', base),
    s: rem('16px', base),
    xs: rem('14px', base),
    xxs: rem('12px', base),
    xxxs: rem('11px', base)
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900
  }
}
